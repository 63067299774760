import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const urlFull: string = state.url;
    const url: string = urlFull.split('?')[0];

    const token: string = this.auth.token;

    if (!token) {
      // localStorage.clear();
      localStorage.removeItem('API_KEY');
      localStorage.removeItem('ROLE');

      const allowedForGuests = ['/auth'];
      if (!allowedForGuests.includes(url)) {
        this.router.navigate(['/auth']);
      }
      // Return here to skip next stage that does opposite
      return true;
    }

    const forbiddenForUser = ['/auth'];
    if (forbiddenForUser.includes(url)) {
      this.router.navigate(['/dashboard/h']);
    }

    return true;
  }
}
