import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class UtilsService {
  constructor(
    private _snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  handleError<T>(operation = 'operation', result?: T) {
    return (data: any): Observable<T> => {
      // Sometimes(?) error response doesn't contain error object so we handle it manually
      let errorMsg = 'Connection error';
      // http error
      if (data && data.error) {
        // Message handler
        if (data.error.message && typeof data.error.message === 'string') {
          let msg = data.error.message;
          // Prompt toaster message and pass error next
          this._snackBar.open(msg, 'Clear', {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: 'snackbar-error',
          });
        } else {
          let errorArray = Object.values(data.error);
          if (errorArray && errorArray.length) {
            let message = '';
            errorArray.forEach((er: any, index) => {
              if (typeof er[0] === 'string') {
                let msg = er[0];
                // Prompt toaster message and pass error next
                this._snackBar.open(msg, 'Clear', {
                  duration: 3000,
                  verticalPosition: 'top',
                  panelClass: 'snackbar-error',
                });
              }
            });

            errorMsg = message;
          }
        }
      }

      return throwError(data);

      // Ignore error and return data to subscriber as success
      // return of(data as T);
    };
  }

  async successToast(): Promise<void> {
    let i18n_message = await this.translateService.get('SUCCESS').toPromise();
    this._snackBar.open(i18n_message, 'Clear', {
      duration: 3000,
      verticalPosition: 'top',
    });
  }
  async productAddedToast(): Promise<void> {
    let i18n_message = await this.translateService
      .get('PRODUCT_ADDED')
      .toPromise();
    this._snackBar.open(i18n_message, 'Clear', {
      duration: 3000,
      verticalPosition: 'top',
    });
  }
}
