import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router,
    private authService: AuthService
  ) {}

  //function which will be called for all http calls
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.token) {
      request = this.addToken(request, this.auth.token);
    }
    return next.handle(request).pipe(
      catchError((error) => {
        // No token provided
        if (
          error.status === 403 &&
          error.error.message === 'User is not logged in.'
        ) {
          this.authService.logout().subscribe((res) => {
            this.router.navigate(['/auth']);
          });
        }
        // Bad token provided
        if (error.status === 401) {
          this.authService.logout().subscribe((res) => {
            this.router.navigate(['/auth']);
          });
        }

        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // return request with added auth jwt token
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
